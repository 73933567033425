@import '../../styles//StyleVariables.scss';

.SearchSidebarContainer {
    position: fixed;
    height: 100%;
    width: 350px;
    z-index: 500;
    padding: $bigPaddingAmount;
}

.SearchSidebarBar {
    background: white;
    height: calc(100% - #{2 * $bigPaddingAmount});
}

.PizzaLocationsContainer {
    overflow-y: scroll;
    height: calc(100% - #{$sidebarHeaderHeight});
    scroll-padding: $normalPaddingAmount;
}

.SidebarHeader {
    height: $sidebarHeaderHeight;
    border-bottom: 1px solid $background-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: bold;
    background-color: $pizzaOrange;
}

.PizzaPlaceCard {
    border: 1px solid $background-grey;
    padding: $normalPaddingAmount;
}

.PizzaPlaceCardWrapper {
    margin: $normalPaddingAmount;
}

.LoadMoreInfoButton {
    cursor: pointer;
    margin-top: 4px;
}

.PizzaLocationHovered {
    -webkit-box-shadow: 2px 10px 44px 0px $background-grey;
    -moz-box-shadow: 2px 10px 44px 0px $background-grey;
    box-shadow: 2px 10px 44px 0px $background-grey;
    border: 1px solid $pizzaOrange;
}
