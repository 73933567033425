// Geometric Values
$bigPaddingAmount: 30px;
$normalPaddingAmount: 10px;
$sidebarHeaderHeight: 50px;

// Colors
$background-grey: #d8d3c6;
$pizzaOrange: #ff6500;
$pizzaRed: #702424;
$pizzaGrey: #f5efdb;
