.shop-marker {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-6-3 3:9:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * 
 * Adapted for goto.pizza
 * 
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    15% {
        -webkit-transform: translateX(-9px) rotate(-6deg);
        transform: translateX(-9px) rotate(-6deg);
    }
    30% {
        -webkit-transform: translateX(9px) rotate(6deg);
        transform: translateX(9px) rotate(6deg);
    }
    45% {
        -webkit-transform: translateX(-9px) rotate(-3.6deg);
        transform: translateX(-9px) rotate(-3.6deg);
    }
    60% {
        -webkit-transform: translateX(6px) rotate(2.4deg);
        transform: translateX(6px) rotate(2.4deg);
    }
    75% {
        -webkit-transform: translateX(-3px) rotate(-1.2deg);
        transform: translateX(-3px) rotate(-1.2deg);
    }
}
@keyframes wobble-hor-bottom {
    0%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    15% {
        -webkit-transform: translateX(-9px) rotate(-6deg);
        transform: translateX(-9px) rotate(-6deg);
    }
    30% {
        -webkit-transform: translateX(9px) rotate(6deg);
        transform: translateX(9px) rotate(6deg);
    }
    45% {
        -webkit-transform: translateX(-9px) rotate(-3.6deg);
        transform: translateX(-9px) rotate(-3.6deg);
    }
    60% {
        -webkit-transform: translateX(6px) rotate(2.4deg);
        transform: translateX(6px) rotate(2.4deg);
    }
    75% {
        -webkit-transform: translateX(-3px) rotate(-1.2deg);
        transform: translateX(-3px) rotate(-1.2deg);
    }
}

.wobble-hor-bottom {
    -webkit-animation: wobble-hor-bottom 0.8s both;
    animation: wobble-hor-bottom 0.8s both;
}
